// Brandenburg-Kapital colors
$ci-color-white:			#fff;
$ci-color-shiraz:			#bf0f35;
$ci-color-petite-orchid:	#df879a;
$ci-color-black:			#000;

// grey tones
$ci-color-concret:		#f3f3f3;
$ci-color-alto:			#d8d8d8;
$ci-color-gray:			#bebebe;
$ci-color-dusty-gray:	#979797;
$ci-color-oslo:			#858790;
$ci-color-tundora:		#424242;
$ci-color-abbey:		#46484a;

// global
$ci-default-base-color:			$ci-color-tundora;
$ci-default-headline-color:		$ci-color-abbey;
$ci-secondary-headline-color:	$ci-color-tundora;
$ci-inverted-headline-color:	$ci-color-white;
$ci-wide-background:			$ci-color-concret;
$ci-inverted-text-color:		$ci-color-white;

$ci-link-color:			$ci-color-shiraz;
$ci-link-decoration:	$ci-color-shiraz;
$ci-link-icon-color:	$ci-color-shiraz;

$ci-btn-bg:		$ci-color-shiraz;
$ci-btn-link:	$ci-color-white;
$ci-btn-shadow:	$ci-color-gray;
$ci-btn-icon:	$ci-color-white;

$ci-input-border: 		$ci-color-tundora;
$ci-input-error: 		$ci-color-shiraz;
$ci-input-placeholder:	$ci-color-tundora;

$ci-list-bullet-color:	$ci-color-shiraz;
$ci-list-count-color:	$ci-color-shiraz;

$ci-table-border: $ci-color-dusty-gray;

// header
$header-gradient:			linear-gradient(to top, rgba(255, 255, 255, .95), $ci-color-white);
$header-icon-color:			$ci-color-shiraz;
$header-mobile-bg-color:	$ci-color-white;

$nav-mobile-bg-color:	$ci-color-white;
$nav-border-top-color:	$ci-color-alto;
$nav-border-bottom:		$ci-color-dusty-gray;
$nav-item-border-color:	$ci-color-concret;

$lang-switch-bg-color: 			$ci-color-abbey;
$lang-switch-link-color: 		$ci-color-white;
$lang-switch-separator-color:	$ci-color-white;

$gendertip-bg-color:		$ci-color-abbey;
$gendertip-border-color:	$ci-color-concret;
$gendertip-icon-fill:		$ci-color-white;
$gendertip-label-color:		$ci-color-white;
$gendertip-text-color:		$ci-color-white;
$gendertip-layer:			rgba(255, 255, 255, .95);
$gendertip-layer-arrow:		$ci-color-shiraz;

$search-layer:				rgba(255, 255, 255, .95);
$search-layer-scrolling:	rgba(255, 255, 255, .95);
$search-layer-mobile:		$ci-color-white;
$search-border-color:		$ci-color-dusty-gray;
$search-placeholder:		$ci-color-shiraz;
$search-item-border-color:	$ci-color-gray;

// footer
$footer-content-color:			$ci-color-white;
$footer-bg-color:				$ci-color-abbey;
$footer-separator-color:		$ci-color-alto;
$footer-color:					$footer-content-color;
$footer-title-color:			$footer-content-color;
$footer-icon-color:				$footer-content-color;
$footer-link-color:				$footer-content-color;
$footer-contact-title-color:	$footer-content-color;
$footer-contact-icon-color:		$footer-content-color;
$footer-contact-link-color:		$footer-content-color;

$scroll-to-top-bg:		$ci-color-shiraz;
$scroll-to-top-icon:	$ci-color-white;

// components
$separator-bg:			$ci-color-abbey;
$separator-bg-inverted:	$ci-color-white;
$separator-bg-large:	$ci-color-alto;

$stage-headline:				$ci-color-white;
$stage-content:					$ci-color-white;
$stage-content-bg:				$ci-color-black;
$stage-content-separator:		$ci-color-white;
$stage-border:					$ci-color-white;
$stage-link:					$ci-color-white;
$stage-icon-down:				$ci-color-white;
$stage-social-bubble:			$ci-color-shiraz;
$stage-social-bubble-active:	$ci-color-white;
$stage-social-bubble-shadow:	$ci-color-alto;
$stage-social-close:			$ci-color-shiraz;
$stage-social-icon:				$ci-color-white;
$stage-social-layer:			$ci-color-white;
$stage-social-layer-arrow:		$ci-color-shiraz;
$stage-social-layer-shadow:		$ci-color-alto;
$stage-detail-headline:			$ci-color-abbey;
$stage-detail-content:			$ci-color-abbey;

$bubble-bg:		$ci-color-shiraz;
$bubble-shadow:	$ci-color-oslo;
$bubble-icon:	$ci-color-white;

$quotes-text:	$ci-color-white;

$usp-count:	$ci-color-white;
$usp-title:	$ci-color-white;

$employee-data-bg:		rgba(191, 15, 53, .65);
$employee-icon:			$ci-color-white;
$employee-text:			$ci-color-white;
$employee-fp-data-bg:	rgba(255, 255, 255, .65);
$employee-fp-icon:		$ci-color-abbey;
$employee-fp-text:		$ci-color-abbey;

$news-teaser-date:		$ci-default-base-color;
$news-teaser-content:	$ci-default-base-color;

$career-teaser-content:	$ci-default-base-color;
$career-teaser-info:	$ci-default-base-color;
$career-border:			$ci-color-concret;

$teaserlist-border:	$ci-color-alto;

$slide-button:			$ci-color-petite-orchid;
$slide-button-disabled:	$ci-color-dusty-gray;
$slide-button-active:	$ci-color-shiraz;

$company-teaser-border: 					$ci-color-alto;
$company-teaser-border-active:				$ci-color-gray;
$company-teaser-description-layer:			$ci-color-abbey;
$company-teaser-description-layer-arrow:	$ci-color-abbey;
$company-teaser-exit:						$ci-color-shiraz;
$company-teaser-exit-bg:					$ci-color-white;
$company-teaser-icon:						$ci-color-white;
$company-teaser-items:						$ci-color-white;

$download-link-title:	$ci-color-shiraz;

$tabs-title:			$ci-color-petite-orchid;
$tabs-title-active:		$ci-color-shiraz;
$tabs-background:		$ci-color-white;
$tabs-background-wide:	$ci-wide-background;

$form-thank-you-bg-color:	$ci-color-white;
