ul,
ol {
	padding-left: 25px;

	li {
		margin-bottom: 1rem;

		&:last-child {
			margin-bottom: 0;
		}

		.c-content & {
			margin-bottom: 2.4rem;
		}
	}
}

ul {

	&.e-content-list {

		li {
			position: relative;
			list-style: none;

			&::before {
				content: '';
				position: absolute;
				top: 8px;
				left: -14px;
				display: block;
				border-radius: 50%;
				width: 5px;
				height: 5px;
				background-color: $ci-list-bullet-color;
			}
		}
	}
}

ol {
	list-style: none;
	counter-reset: li;

	li {
		counter-increment: li;

		&::before {
			content: counter(li);
			display: inline-block;
			margin-right: .5em;
			margin-left: -1.5em;
			width: 1em;
			text-align: right;
			color: $ci-list-count-color;
			direction: rtl;
		}
	}
}
