.c-usp {
	background-position: center center;
	background-size: cover;

	&__items {
		display: flex;
		flex-direction: row;
		justify-content: center;

		@include media($global-mq-tablet-and-mobile...) {
			flex-direction: column;
		}
	}

	&__item {
		width: 100%;

		@include media($global-mq-tablet-and-mobile...) {
			margin-bottom: 20px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__count {
		display: inline-block;
		font-size: 7.5rem;
		line-height: (92/75);
		color: $usp-count;

		&::after {
			content: '+';
			font-size: 7.5rem;
			line-height: (92/75);
			color: $usp-count;
		}
	}

	&__title {
		font-size: 2rem;
		font-weight: 600;
		line-height: (32/20);
		text-transform: uppercase;
		color: $usp-title;

		@include media($global-mq-mobile...) {
			font-size: rem(16);
		}
	}

	&__subtitle {
		color: $usp-title;
	}
}
