.c-teaser-company {

	&__close {
		position: absolute;
		top: -30px;
		right: 30px;
		padding: 5px;
		width: 30px;
		height: 30px;
		cursor: pointer;

		@include media($global-mq-tablet-and-mobile...) {
			top: -45px;
			right: 0;
		}

		&-icon {
			width: 20px;
			height: 20px;
			fill: $company-teaser-icon;
		}
	}

	&__image-wrapper {
		position: relative;
		margin: 0 auto;
		border: 2px solid $company-teaser-border;
		border-radius: 50%;
		width: 220px;
		height: 220px;
		cursor: pointer;

		@include media($global-mq-tablet-and-mobile...) {
			width: 130px;
			height: 130px;
		}

		&::after {
			content: '';
			position: absolute;
			right: 0;
			bottom: -42px;
			left: 0;
			margin: 0 auto;
			border-style: solid;
			border-width: 0 20px 20px;
			border-color: transparent transparent $company-teaser-description-layer-arrow;
			width: 0;
			height: 0;
			opacity: 0;
			transition: opacity .125s ease-in;
			transform: rotate(360deg);

			@include media($global-mq-tablet-and-mobile...) {
				bottom: -32px;
			}
		}

		.active & {
			border-color: $company-teaser-border-active;

			&::after {
				opacity: 1;
				transition: opacity .25s .25s ease-in;
			}
		}

		@include hocus() {
			border-color: $company-teaser-border-active;

			.c-teaser-company__image {
				filter: grayscale(0%);
			}
		}
	}

	&__exit {
		position: absolute;
		top: 5px;
		right: 5px;
		display: block;
		border: 1px solid $company-teaser-exit;
		border-radius: 50%;
		width: 60px;
		height: 60px;
		font-weight: 600;
		line-height: (60/16);
		text-transform: uppercase;
		text-align: center;
		color: $company-teaser-exit;
		background-color: $company-teaser-exit-bg;

		@include media($global-mq-tablet-and-mobile...) {
			width: 30px;
			height: 30px;
			font-size: .8rem;
			line-height: (30/8);
		}
	}

	&__image {
		display: block;
		width: 150px;
		height: auto;
		transform: translate(35px, 65px);
		filter: grayscale(100%);
		transition: filter .1s linear;

		@include media($global-mq-tablet-and-mobile...) {
			display: block;
			width: 100px;
			transform: translate(15px, 35px);
		}

		.active & {
			filter: grayscale(0%);
		}
	}

	&__description-wrapper {
		position: absolute;
		right: 50%;
		left: 50%;
		margin: 40px -50vw 0;
		padding: 60px 0;
		width: 100vw;
		height: auto;
		overflow: hidden;
		background-color: $company-teaser-description-layer;
		opacity: 0;
		transition-property: height opacity;
		transition-duration: .125s;
		transition-timing-function: ease-in;

		@include media($global-mq-tablet-and-mobile...) {
			margin-top: 30px;
			padding-right: 15px;
			padding-left: 15px;
		}

		.active & {
			z-index: z('company-description');
			display: flex;
			opacity: 1;
			transition: height .25s .25s ease-in, opacity .25s .25s ease-in;
		}

		> .o-wrap {
			position: relative;
			width: 100%;
		}

		p {
			font-size: rem(20);
			line-height: (24/20);

			@include media($global-mq-mobile...) {
				font-size: rem(16);
				line-height: (22/16);
			}
		}
	}

	&__items {
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin-top: 60px;

		@include media($global-mq-tablet-and-mobile...) {
			flex-direction: column;
			margin-top: 40px;
		}
	}

	&__item {
		width: 100%;

		@include media($global-mq-tablet-and-mobile...) {
			margin-bottom: 40px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		&-label {
			font-size: 2rem;
			font-weight: 400;
			line-height: (24/20);
			text-transform: initial;
			color: $company-teaser-items;

			@include media($global-mq-tablet-and-mobile...) {
				margin-bottom: 10px;
			}
		}

		&-content {
			color: $company-teaser-items;
		}
	}
}
