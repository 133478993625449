.c-contact {
	position: relative;
	flex: 0 1 315px;
	margin-right: 30px;

	@include media($global-mq-tablet-and-mobile...) {
		flex-basis: auto;
		margin-right: 0;
		padding-top: 20px;
		padding-bottom: 30px;
	}

	&__title {
		margin-bottom: 15px;
		font-size: 2rem;
		font-weight: 400;
		color: $footer-contact-title-color;
	}

	&__address {
		margin-bottom: 20px;
	}

	&__mail {
		color: $footer-contact-link-color;
	}

	&__icon {
		fill: $footer-contact-icon-color;

		&--fax,
		&--phone {
			margin-right: 10px;
			width: 16px;
			height: 16px;
		}

		&--mail {
			margin-right: 12px;
			margin-left: 2px;
			width: 12px;
			height: 16px;
		}
	}
}
