$ci-headline-1-size:		56;
$ci-headline-1-lineheight:	64;
$ci-headline-2-size:		30;
$ci-headline-2-lineheight:	38;
$ci-headline-3-size:		18;
$ci-headline-3-lineheight:	22;
$ci-headline-4-size:		20;
$ci-headline-4-lineheight:	26;
$ci-headline-5-size:		16;
$ci-headline-5-lineheight:	24;

h1 {
	@include heading(headline-2, $ci-default-headline-color, 20);
	font-weight: 600;

	@include media($global-mq-mobile...) {
		font-size: rem(22);
	}
}

h2 {
	@include heading(headline-2, $ci-secondary-headline-color, 20);
	font-weight: 600;

	.e-color--invert & {
		color: $ci-inverted-headline-color;

		a {
			color: $ci-inverted-headline-color;
		}
	}

	@include media($global-mq-mobile...) {
		font-size: rem(22);
	}
}

h3 {
	@include heading(headline-3, $ci-secondary-headline-color, 20);
	font-weight: 500;
	text-transform: uppercase;

	@include media($global-mq-mobile...) {
		font-size: rem(16);
	}
}

h4, h5 {
	@include heading(headline-5, $ci-secondary-headline-color, 16);
	font-weight: 600;

	@include media($global-mq-mobile...) {
		font-size: rem(16);
	}
}

h1, h2, h3, h4, h5 {

	&:last-child {
		margin-bottom: 0;
	}
}
