.c-separator {
	display: block;
	margin: 0 auto;
	border: none;
	width: 100px;
	height: 1px;
	background-color: $separator-bg;

	&--wide {
		border: none;
		width: 100%;
		height: 1px;
		background-color: $separator-bg-large;
	}

	.e-color--invert & {
		background-color: $separator-bg-inverted;
	}

	.c-teaser-company & {
		height: 4px;
	}
}
