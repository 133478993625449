%social-link {
	position: relative;
	display: block;
	width: 60px;
	height: 60px;
	background-color: $stage-social-bubble;
	box-shadow: 0 0 13px 0 $stage-social-bubble-shadow;
	transform: scale(1);
	transition: transform .1s linear;
	cursor: pointer;

	@include hocus() {
		transform: scale(1.1);
	}
}

.c-stage__social {
	@include media($global-mq-tablet-and-mobile...) {
		text-align: center;
	}

	&-list {
		padding-top: 20px;
		padding-left: 0;

		@include media($global-mq-tablet-and-mobile...) {
			padding-top: 60px;
		}

		.c-stage__inner:first-child & {
			padding-top: 60px;
		}
	}

	&-item {
		position: relative;
		display: inline-block;
		margin-right: 10px;
		margin-bottom: 0;

		&:last-child {
			margin-right: 0;
		}

		&::before {
			width: 0;
			height: 0;
		}
	}

	&-link {
		@extend %social-link;
		border-radius: 50%;

		@include hocus() {
			transform: scale(1.1);
		}
	}

	&-icon {
		display: block;
		padding: 15px;
		width: 60px;
		height: 60px;
		fill: $stage-social-icon;
	}

	&-close {
		display: none;
		padding: 20px;
		fill: $stage-social-close;
	}

	&-layer {
		position: absolute;
		bottom: -20px;
		left: -20px;
		display: none;
		padding: 30px 20px;
		min-width: 100px;
		box-shadow: 0 0 13px 0 $stage-social-layer-shadow;
		transform: translate(0, 100%);
		background-color: $stage-social-layer;

		&::before {
			content: '';
			position: absolute;
			top: -10px;
			left: 40px;
			border-style: solid;
			border-width: 0 10px 10px;
			border-color: transparent transparent $stage-social-layer-arrow;
			width: 0;
			height: 0;
			transform: rotate(360deg);
		}

		&-list {
			padding: 0;
		}

		&-item {
			margin-bottom: 1.5rem;
			list-style: none;
		}

		&-link {
			display: block;
			height: 28px;
		}
	}

	.contenttype-detail & {
		margin-top: auto;
	}
}

.c-stage__social-button:checked {

	+ .c-stage__social-toggle {

		.c-stage__social-link {
			transform: scale(1.1);
			background-color: $stage-social-bubble-active;
		}

		.c-stage__social-icon--share {
			display: none;
		}

		.c-stage__social-close {
			display: block;
		}
	}

	~ .c-stage__social-layer {
		display: block;
	}
}
