.c-stage__logo {
	display: block;
	max-width: 300px;

	@include media($global-mq-tablet-and-mobile...) {
		margin-right: auto;
		margin-left: auto;
	}

	img {
		display: block;
		width: 100%;
		height: auto;
	}
}
