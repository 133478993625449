@mixin heading($headline, $color, $margin-bottom) {

	@if $headline == headline-1 {
		font-size: rem($ci-headline-1-size);
		line-height: ($ci-headline-1-lineheight/$ci-headline-1-size);
	} @else if $headline == headline-2 {
		font-size: rem($ci-headline-2-size);
		line-height: ($ci-headline-2-lineheight/$ci-headline-2-size);
	} @else if $headline == headline-3 {
		font-size: rem($ci-headline-3-size);
		line-height: ($ci-headline-3-lineheight/$ci-headline-3-size);
	} @else if $headline == headline-4 {
		font-size: rem($ci-headline-4-size);
		line-height: ($ci-headline-4-lineheight/$ci-headline-4-size);
	}

	margin-bottom: rem($margin-bottom);
	color: $color;
}
