/**
 * Page-level styling (e.g. HTML and BODY elements).
 */

html {
	font-size: ($global-font-size / 16px) * 1em;
	line-height: $global-line-height;
	font-family: $global-font-stack;
	color: $ci-default-base-color;
}

body {
	position: relative;
	overflow-x: hidden;
	font-size: rem($global-body-font-size);
	line-height: $global-line-height;

	@include media($global-mq-mobile...) {
		font-size: rem(14);
	}
}

#main {

	@include media($global-mq-mobile...) {
		overflow: hidden;
	}
}

.contenttype {

	&-overview,
	&-detail {

		#highlight {
			padding-top: 110px;
		}
	}

	&-text {

		#main {
			padding-top: 200px;

			@include media($global-mq-tablet-and-mobile...) {
				padding-top: 160px;
			}
		}
	}
}
