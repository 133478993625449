.c-tabs {
	box-sizing: border-box;

	&.c-tabslider {

		.c-tabs__list {

			@include media($global-mq-tablet-and-mobile...) {
				display: none;
			}
		}
	}

	&.o-wrap--wide {

		.c-slide__button {
			background: $tabs-background-wide;
		}
	}

	&__list {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 60px;
	}

	&__tab {
		position: relative;
		flex: 0 1 auto;
		margin-bottom: 0;
		padding: 20px 25px 30px;
		font-size: 3rem;
		font-weight: 400;
		text-transform: none;
		text-align: center;
		color: $tabs-title;
		cursor: pointer;

		@include media($global-mq-desktop-and-header...) {
			padding-right: 15px;
			padding-left: 15px;
			font-size: 2rem;
		}

		&::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 50%;
			display: block;
			width: 100px;
			height: 3px;
			background-color: $tabs-title-active;
			transform: translateX(-50%);
			opacity: 0;
			transition: opacity .1s ease;
		}

		&.is-active {
			font-weight: 600;
			color: $tabs-title-active;

			&::after {
				opacity: 1;
			}
		}
	}

	&__content {
		display: none;

		.c-tabs__tab {
			text-align: center;
		}

		&.is-active {
			display: block;
		}

		.slick-initialized & {
			display: block;
		}

		.c-slider__title {
			margin-bottom: 60px;
			padding: 20px 25px 30px;
			font-size: 3rem;
			font-weight: 400;
			text-transform: none;
			text-align: center;
			color: $tabs-title-active;

			@include media($global-mq-tablet-and-mobile...) {
				position: relative;
				padding-top: 25px;
				font-size: 2rem;

				&::after {
					content: '';
					position: absolute;
					bottom: 0;
					left: 50%;
					display: block;
					width: 100px;
					height: 3px;
					background-color: $tabs-title-active;
					transform: translateX(-50%);
				}
			}

			@include media($global-mq-mobile...) {
				padding-right: 50px;
				padding-left: 50px;
			}

			@include media($global-mq-desktop...) {
				display: none;
			}
		}
	}

	.c-slide__button {
		position: absolute;
		top: 15px;
		z-index: 5;
		border: none;
		padding: 10px;
		width: 50px;
		font-size: 3rem;
		line-height: (28/ 30);
		color: $slide-button;
		background: $tabs-background;
		cursor: pointer;

		&-icon {
			width: 24px;
			height: 24px;
			fill: $slide-button-active;
		}

		&--left {
			left: 0;

			@include media($global-mq-tablet-and-mobile...) {
				left: -15px;
				padding-left: 15px;
			}
		}

		&--right {
			right: 0;

			@include media($global-mq-tablet-and-mobile...) {
				right: -15px;
				padding-right: 15px;
			}
		}
	}
}
