/**
 * fluid-type - https://www.smashingmagazine.com/2016/05/fluid-typography/
 * @param {px} $min-vw - min viewport
 * @param {px} $max-vw - max viewport
 * @param {px} $min-value - min font-size
 * @param {px} $max-value - max font-size
 */
@mixin fluid-type($min-vw, $max-vw, $min-value, $max-value) {
	font-size: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
}
