@mixin u-hide {
	display: none;
}

.u-hide {
	@include u-hide;

	// large
	@include media($global-mq-desktop...) {

		&\@lg {
			@include u-hide;
		}
	}

	// medium
	@include media($global-mq-tablet...) {

		&\@md {
			@include u-hide;
		}
	}

	// small
	@include media($global-mq-mobile...) {

		&\@sm {
			@include u-hide;
		}
	}
}
