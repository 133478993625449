.o-page-module {
	margin-bottom: $global-margin-bottom;

	@include media($global-mq-tablet-and-mobile...) {
		margin-bottom: $global-mobile-margin-bottom;
	}

	&--small {
		margin-bottom: $global-margin-bottom-small;

		@include media($global-mq-tablet-and-mobile...) {
			margin-bottom: $global-mobile-margin-bottom-small;
		}
	}

	&--big {
		margin-bottom: $global-margin-bottom-big;

		@include media($global-mq-tablet-and-mobile...) {
			margin-bottom: $global-mobile-margin-bottom-big;
		}
	}

	&:last-child {
		margin-bottom: 0;
	}
}
