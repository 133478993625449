@mixin footer-separator {
	content: '';
	position: absolute;
	bottom: 0;
	display: block;
	width: calc(100% + 30px);
	height: 1px;
	background-color: $footer-separator-color;
}

.c-footer {
	position: relative;
	margin-top: 100px;
	padding-top: 80px;
	padding-bottom: 60px;
	color: $footer-color;
	background-color: $footer-bg-color;

	@include media($global-mq-tablet-and-mobile...) {
		margin-top: 60px;
		padding-bottom: 0;
	}

	&__inner {
		display: flex;

		@include media($global-mq-tablet-and-mobile...) {
			flex-direction: column;
		}

		> div,
		> .c-footer__nav-misc {

			&::before {
				right: -15px;
				left: -15px;

				@include media($global-mq-tablet-and-mobile...) {
					@include footer-separator;
				}
			}

			//&::after {
			//	right: -50%;

			//	@include media($global-mq-tablet-and-mobile...) {
			//		@include footer-separator;
			//	}
			//}*/
		}
	}

	&__about {
		position: relative;
		flex: 0 1 540px;
		margin-right: 30px;

		@include media($global-mq-tablet-and-mobile...) {
			flex-basis: auto;
			margin-right: 0;
			padding-bottom: 30px;
		}
	}

	&__nav-misc {
		position: relative;
		flex: 1 0 auto;
		margin-right: 30px;

		@include media($global-mq-tablet-and-mobile...) {
			margin-right: 0;
			padding-top: 20px;
			padding-bottom: 5px;
		}
	}

	&__nav-legal {
		position: relative;
		flex: 0 0 auto;

		@include media($global-mq-tablet-and-mobile...) {
			padding-top: 20px;
			padding-bottom: 5px;
		}
	}

	.c-logo {
		display: block;
		margin-top: -20px;
		margin-bottom: 15px;

		&__svg {
			display: block;
			width: 100%;
			max-width: 315px;
			max-height: 83px;
		}
	}

	&__button {
		display: none;
	}

	&__toggle {
		display: block;

		&--icon {
			display: none;

			@include media($global-mq-tablet-and-mobile...) {
				position: absolute;
				top: 0;
				right: 0;
				display: block;
				width: 20px;
				height: 20px;
				fill: $footer-icon-color;
			}
		}
	}
}

.c-footer-list {

	@include media($global-mq-tablet-and-mobile...) {
		max-height: 0;
		overflow: hidden;
		transition: all .1s linear;
	}

	&__title {
		position: relative;
		margin-bottom: 15px;
		font-size: 2rem;
		font-weight: 400;
		color: $footer-title-color;
	}

	&__icon {
		margin-right: 14px;
		width: 9px;
		height: 16px;
		fill: $footer-icon-color;
	}

	&__link {
		display: flex;
		align-items: center;
		color: $footer-link-color;
	}
}

.c-footer__button:checked {

	+ .c-footer-list__title {

		.c-footer__toggle {

			&--icon {
				transform: rotate(90deg);
			}
		}
	}

	~ .c-footer-list {
		padding-bottom: 15px;
		max-height: 500px;
	}
}
