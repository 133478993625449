.u-page-portfolio {

	.c-tabslider {
		padding: 0;
		max-width: 100%;

		@include media($global-mq-tablet-and-mobile...) {
			padding-right: 0;
			padding-left: 0;
		}
	}

	.c-portfolio {

		.c-teaserlist__wrapper {
			margin-right: 0;
			margin-left: 0;
		}
	}

	.o-wrap + .o-wrap {
		padding-top: 0;
		padding-bottom: 0;
	}

	.c-tabs__content {

		.o-wrap--wide {
			margin-top: 0;
			margin-bottom: 0;
			padding-top: 60px;
			padding-bottom: 60px;

			@include media($global-mq-tablet-and-mobile...) {
				margin-top: 0;
				margin-bottom: 0;
				padding: 40px 15px;
			}

			&.c-teaserlist {
				padding-top: 0;
			}

			.c-teaserlist__item {

				@include media($global-mq-tablet-and-mobile...) {
					width: 100%;
				}
			}
		}
	}

	.c-footer {
		margin-top: 0;
	}

	@include media($global-mq-tablet-and-mobile...) {
		.slick-slide {
			opacity: 0;
			transition: opacity .1s linear;

			&.slick-active {
				opacity: 1;
			}
		}

		.c-slide__button {

			&--right {
				right: 0;
			}

			&--left {
				left: 0;
			}
		}
	}
}
