.c-scroll-to-top {
	position: absolute;
	top: -30px;
	right: 0;
	left: 0;
	display: block;
	margin: 0 auto;
	border-radius: 50%;
	width: 60px;
	height: 60px;
	background-color: $scroll-to-top-bg;

	&__icon {
		position: relative;
		top: 15px;
		display: block;
		margin: 0 auto;
		width: 30px;
		height: 30px;
		transform: rotate(-90deg);
		fill: $scroll-to-top-icon;
	}
}
