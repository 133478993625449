p,
ul,
ol {
	margin-bottom: 2.4rem;

	&:last-child {
		margin-bottom: 0;
	}
}

b,
strong {
	font-weight: 500;
}
