%content-separator {
	content: '';
	display: block;
	width: 100px;
	height: 1px;
	background-color: $separator-bg;
}

.c-content {

	h2 {
		margin: 60px 0 40px;
		text-align: center;

		+ h3 {
			margin-top: -20px;
		}

		+ .o-column,
		+ p {
			&::before {
				@extend %content-separator;
				margin: -20px auto 40px;
			}
		}
	}

	h3 {
		margin: 60px 0 40px;
		text-align: center;

		&::after {
			@extend %content-separator;
			margin: 20px auto 0;
		}
	}

	ul {

		li {
			position: relative;
			margin-bottom: 0;
			list-style: none;

			&::before {
				content: '';
				position: absolute;
				top: 8px;
				left: -14px;
				display: block;
				border-radius: 50%;
				width: 5px;
				height: 5px;
				background-color: $ci-list-bullet-color;
			}
		}
	}

	ol {
		list-style: none;
		counter-reset: li;

		li {
			counter-increment: li;

			&::before {
				content: counter(li);
				display: inline-block;
				margin-right: .5em;
				margin-left: -1.5em;
				width: 1em;
				text-align: right;
				color: $ci-list-count-color;
				direction: rtl;
			}
		}
	}
}
