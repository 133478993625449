%o-btn {
	display: inline-block;
	border: none;
	border-radius: 5px;
	padding: 15px 30px;
	min-width: 26.5rem;
	max-width: 37.5rem;
	font-size: 1.8rem;
	line-height: (24/18);
	text-transform: uppercase;
	text-align: center;
	color: $ci-btn-link;
	background-color: $ci-btn-bg;
	box-shadow: 0;
	transition: box-shadow .2s ease-in-out;
}

.o-btn {
	@extend %o-btn;

	@include hocus() {
		text-decoration: none;
		box-shadow: 0 2px 10px 2px $ci-btn-shadow;
	}

	@include media($global-mq-tablet-and-mobile...) {
		font-size: 1.6rem;
		line-height: (22/16);
	}
}
