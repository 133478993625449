.c-map {
	padding: 0;
	height: 500px;

	&__wrapper {
		height: 100%;
	}

	@include media($global-mq-tablet-and-mobile...) {
		padding: 0;
	}
}
