.o-input {
	border: 1px solid $ci-input-border;
	border-radius: 5px;
	padding: 15px;
	width: 100%;
	height: 56px;
	font-size: 1.8rem;
	line-height: (24/18);

	.has-error & {
		border-color: $ci-input-error;

		+ label {
			font-weight: 600;
			color: $ci-input-error;
		}
	}

	@include hocus() {
		outline: none;
	}

	&::placeholder {
		font-weight: 400;
		font-style: italic;
		color: $ci-input-placeholder;
	}

	&[type=search] {
		border: none;
		padding: 0 35px 0 0;
		width: 100%;
		background-color: transparent;

		@include hocus() {
			outline: none;
		}

		&::placeholder {
			font-weight: 500;
			font-style: italic;
			color: $search-placeholder;
			opacity: .65;
		}

		/* clears the 'X' from Internet Explorer */
		&::-ms-reveal,
		&::-ms-clear {
			display: none;
			width: 0;
			height: 0;
		}

		/* clears the 'X' from Chrome */
		&::-webkit-search-decoration,
		&::-webkit-search-cancel-button,
		&::-webkit-search-results-button,
		&::-webkit-search-results-decoration {
			display: none;
		}
	}

	&[type=number] {

		&::-webkit-inner-spin-button,
		&::-webkit-outer-spin-button {
			display: none;
			margin: 0;
		}
	}

	&[type=file] {

		&::-webkit-file-upload-button {
			font-size: 1.6rem;
			background-color: transparent;
		}
	}
}
