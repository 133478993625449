@mixin modules-centering {
	margin-right: auto;
	margin-left: auto;
	padding-right: ($global-page-site-padding * 1px);
	padding-left: ($global-page-site-padding * 1px);
	max-width: rem($global-page-width);

	@include media($global-mq-tablet-and-mobile...) {
		padding-right: ($global-page-site-padding-mobile * 1px);
		padding-left: ($global-page-site-padding-mobile * 1px);
	}
}

h1 {
	@include modules-centering;
	margin-bottom: 2rem;
	text-align: center;
}

.wysiwyg {
	@include modules-centering;
	margin-bottom: $global-margin-bottom;
	text-align: center;

	&::after {
		content: '';
		display: block;
		margin: 20px auto 0;
		width: 100px;
		height: 1px;
		background-color: $separator-bg;
	}
}

.form {
	@include modules-centering;
	margin-bottom: 60px;

	@include media($global-mq-tablet-and-mobile...) {
		margin-bottom: 40px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&:last-child {
		margin-bottom: 0;
	}

	form {
		margin: 0 auto;
		padding-right: ($global-page-site-padding * 1px);
		padding-left: ($global-page-site-padding * 1px);
		max-width: 65rem;

		@include media($global-mq-tablet-and-mobile...) {
			padding-right: 0;
			padding-left: 0;
		}

		.swp {
			@include hide-visually;
		}

		.html {

			p {
				margin: 0;
				text-align: center;
			}
		}

		.item {
			margin-bottom: 7px;
		}

		textarea,
		input {
			border: 1px solid $ci-input-border;
			border-radius: 5px;
			padding: 24px 15px 6px;
			width: 100%;
			font-size: 1.8rem;
			line-height: (24/18);

			@include hocus() {
				outline: none;
			}
		}

		textarea {
			padding-bottom: 15px;
			resize: none;
		}

		label {
			position: relative;
			bottom: -26px;
			padding: 0 15px;
			font-size: 1.2rem;
			opacity: .5;
		}

		.select {
			margin-top: 30px;

			label {
				bottom: 0;
				padding-left: 0;
				font-size: 1.6rem;
				opacity: 1;
			}
		}

		.checkbox {
			margin-top: 30px;

			.form-label {
				display: none;
			}

			fieldset {
				margin-bottom: 0;
			}

			ul {
				margin: 0;
				padding: 0;

				li {
					list-style: none;
				}

				input {
					position: relative;
					top: -2px;
					display: inline-block;
					width: auto;
				}

				label {
					bottom: 0;
					display: inline-block;
					padding-right: 0;
					padding-left: 5px;
					font-size: 1.6rem;
					opacity: 1;
				}
			}
		}

		.form-mandatory-indicator {
			color: $ci-input-error;
		}

		.form-button {
			text-align: center;
		}

		.submit {
			@extend %o-btn;

			@include hocus() {
				text-decoration: none;
				box-shadow: 0 2px 10px 2px $ci-btn-shadow;
			}

			@include media($global-mq-tablet-and-mobile...) {
				font-size: 1.6rem;
				line-height: (22/16);
			}
		}
	}

	.c-content {
		padding: 0;

		@include media($global-mq-tablet-and-mobile...) {
			padding: 0;
		}
	}
}
