// Project-level settings and setup.

$global-font-size: 10px !default;
$global-body-font-size: 16px !default;
$global-line-height: 1.5 !default;

$global-font-stack: 'Montserrat', arial, sans-serif !default;
$global-font-stack-webfont: $global-font-stack !default;

$global-page-width: 1410 !default;
$global-page-width-medium: 1180 !default;
$global-page-width-small: 950 !default;
$global-page-site-padding: 30 !default;
$global-page-site-padding-mobile: 15 !default;

$global-margin-bottom: 60px !default;
$global-margin-bottom-small: 40px !default;
$global-margin-bottom-big: 100px !default;
$global-mobile-margin-bottom: 40px !default;
$global-mobile-margin-bottom-small: 20px !default;
$global-mobile-margin-bottom-big: 60px !default;

$global-grid-gap-width: 30px !default;
$global-grid-colmuns: 12 !default;

$global-margin-bottom: 60px !default;
