.u-safety-area {

	&--5 {background-position: 5% 0;}
	&--10 {background-position: 10% 0;}
	&--15 {background-position: 15% 0;}
	&--20 {background-position: 20% 0;}
	&--25 {background-position: 25% 0;}
	&--30 {background-position: 30% 0;}
	&--35 {background-position: 35% 0;}
	&--40 {background-position: 40% 0;}
	&--45 {background-position: 45% 0;}
	&--50 {background-position: 50% 0;}
	&--55 {background-position: 55% 0;}
	&--60 {background-position: 60% 0;}
	&--65 {background-position: 65% 0;}
	&--70 {background-position: 70% 0;}
	&--75 {background-position: 75% 0;}
	&--80 {background-position: 80% 0;}
	&--85 {background-position: 85% 0;}
	&--90 {background-position: 90% 0;}
	&--95 {background-position: 95% 0;}
	&--100 {background-position: 100% 0;}

	&-mobile {

		@include media($global-mq-mobile...) {

			&--5 {background-position: 5% 0;}
			&--10 {background-position: 10% 0;}
			&--15 {background-position: 15% 0;}
			&--20 {background-position: 20% 0;}
			&--25 {background-position: 25% 0;}
			&--30 {background-position: 30% 0;}
			&--35 {background-position: 35% 0;}
			&--40 {background-position: 40% 0;}
			&--45 {background-position: 45% 0;}
			&--50 {background-position: 50% 0;}
			&--55 {background-position: 55% 0;}
			&--60 {background-position: 60% 0;}
			&--65 {background-position: 65% 0;}
			&--70 {background-position: 70% 0;}
			&--75 {background-position: 75% 0;}
			&--80 {background-position: 80% 0;}
			&--85 {background-position: 85% 0;}
			&--90 {background-position: 90% 0;}
			&--95 {background-position: 95% 0;}
			&--100 {background-position: 100% 0;}
		}
	}
}
