.c-stage__inner {

	.contenttype-frontpage &,
	.contenttype-overview & {
		position: absolute;
		top: 50%;
		right: 0;
		left: 0;
		width: 100%;
		transform: translate(0, -50%);
	}

	.contenttype-detail & {
		display: flex;
		flex-direction: column;
		order: 0;
		margin: 0;
		padding-right: 130px;
		width: 50%;
		max-width: 70.5rem;

		&:first-child {
			flex-flow: row wrap;
			margin: 100px auto 0;
			padding-right: ($global-page-site-padding * 1px);
			width: 100%;
			max-width: 100%;
			text-align: center;

			@include media($global-mq-tablet-and-mobile...) {
				margin-top: 60px;
			}

			.c-stage__content {
				flex: 1 1 100%;
				flex-flow: column wrap;
				justify-content: center;
				padding-top: 0;
			}

			.c-stage__social {
				margin: auto;
			}

			.c-stage__text,
			.c-stage__title {
				margin-right: auto;
				margin-left: auto;
				max-width: 89rem;
			}

			.c-stage__text {

				&::after {
					margin-right: auto;
					margin-left: auto;
				}
			}

			.c-stage__logo {
				margin: 0 auto;
			}
		}

		@include media($global-mq-tablet...) {
			padding-right: ($global-page-site-padding * 1px);
		}

		@include media($global-mq-tablet-and-mobile...) {
			display: block;
			order: 1;
			padding-right: ($global-page-site-padding-mobile * 1px);
			width: 100%;
			max-width: 100%;
		}
	}
}
