.c-header {
	position: fixed;
	z-index: z('header');
	width: 100%;
	font-size: 1.8rem;
	background: $header-gradient;

	&__icon {
		fill: $header-icon-color;
	}

	@include media($global-mq-tablet-and-mobile...) {
		background-color: $header-mobile-bg-color;
	}

	&__inner {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 8px;
		width: 100%;
		height: 110px;
		transition: all .3s ease;
	}

	&--scrolling {
		@include media($global-mq-desktop...) {
			animation: .3s ease header-gradient forwards;
		}

		.c-header__inner {
			height: 80px;
		}
	}
}

@keyframes header-gradient {
	0% 		{ background: linear-gradient(to top, rgba(255, 255, 255, .55), $ci-color-white); }
	30%		{ background: linear-gradient(to top, rgba(255, 255, 255, .61), $ci-color-white); }
	50% 	{ background: linear-gradient(to top, rgba(255, 255, 255, .69), $ci-color-white); }
	65% 	{ background: linear-gradient(to top, rgba(255, 255, 255, .71), $ci-color-white); }
	80% 	{ background: linear-gradient(to top, rgba(255, 255, 255, .76), $ci-color-white); }
	90% 	{ background: linear-gradient(to top, rgba(255, 255, 255, .79), $ci-color-white); }
	100% 	{ background: linear-gradient(to top, rgba(255, 255, 255, .95), $ci-color-white); }
}
