.c-searchform {
	position: absolute;
	top: 110px;
	right: 0;
	left: 0;
	display: none;
	padding: 0 30px;
	background-color: $search-layer;
	transition: all .3s ease;
	opacity: 1;

	@include media($global-mq-tablet-and-mobile...) {
		padding: 0;
		background-color: $search-layer-mobile;
	}

	.c-header--scrolling & {
		top: 80px;

		@include media($global-mq-desktop...) {
			background-color: $search-layer-scrolling;
		}
	}

	&__wrapper {
		position: relative;
		margin: 15px auto 0;
		border-top: 1px solid $search-border-color;
		padding: 15px ($global-page-site-padding * 1px);
		max-width: rem($global-page-width);

		@include media($global-mq-tablet-and-mobile...) {
			margin-top: 0;
			padding-right: ($global-page-site-padding-mobile * 1px);
			padding-left: ($global-page-site-padding-mobile * 1px);
		}
	}

	&__icon {
		width: 20px;
		height: 20px;
		cursor: pointer;

		@include media($global-mq-tablet-and-mobile...) {
			width: 26px;
			height: 26px;
		}
	}

	&__btn {
		position: absolute;
		top: 42px;
		right: 20px;
		border: none;
		padding: 10px;
		background: none;
		box-shadow: none;
		transform: translate(0, -50%);

		@include media($global-mq-tablet-and-mobile...) {
			right: 5px;
		}
	}

	&__results {
		margin: 10px -30px 0;
		border-top: 1px solid $search-border-color;
		border-bottom: 1px solid $search-border-color;
		padding: 20px 30px;

		&-wrapper {
			list-style: none;
			margin: 0;
			padding: 0;
			max-height: 500px;
			overflow-y: auto;

			@include media($global-mq-tablet-and-mobile...) {
				max-height: 300px;
			}

			li {
				margin-bottom: 20px;
				border-bottom: 1px solid $search-item-border-color;
				padding-bottom: 20px;

				&:last-child {
					margin: 0;
					border: none;
					padding: 0;
				}

				a {
					@include hocus() {
						text-decoration: none;
					}
				}
			}
		}

		h2 {
			margin-bottom: 0;
			font-size: rem(20);
		}

		p {
			font-size: rem(16);
		}

		span {
			font-size: rem(16);
		}
	}

	&__pager {
		margin-bottom: 20px;
		margin-left: -10px;

		&-btn {
			border: none;
			padding: 10px;
			font-size: rem(30);
			text-align: center;
			color: $ci-color-shiraz;
			background-color: transparent;
			cursor: pointer;
		}
	}
}
