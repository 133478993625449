.c-stage__page-down {
	position: absolute;
	right: 0;
	bottom: 60px;
	left: 0;
	display: block;
	margin: 0 auto;
	width: 60px;
	height: 60px;
	fill: $stage-icon-down;

	@include media($global-mq-tablet-and-mobile...) {
		bottom: 25px;
		width: 50px;
		height: 50px;
	}
}
