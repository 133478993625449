.c-teaser-icon {
	text-align: center;

	&__figure {
		margin: 0 auto 30px;
		width: 200px;
		height: 200px;

		@include media($global-mq-mobile...) {
			margin-bottom: 20px;
			width: 150px;
			height: 150px;
		}

		img,
		svg {
			width: 200px;
			height: 200px;

			@include media($global-mq-mobile...) {
				width: 150px;
				height: 150px;
			}
		}
	}

	&__icon {
		width: 100%;
		height: auto;
	}

	h3 {
		@include heading(headline-4, $ci-secondary-headline-color, 10);
		font-weight: 600;

		@include media($global-mq-mobile...) {
			font-size: rem(18);
		}
	}

	p {
		font-size: rem(18);
		line-height: (22/18);
		text-transform: uppercase;

		@include media($global-mq-mobile...) {
			font-size: rem(16);
		}
	}
}
