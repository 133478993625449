.c-slider {

	.owl-navigation {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 40px;
	}

	.owl-prev,
	.owl-next {
		border: none;
		padding: 10px;
		width: 100px;
		background-color: transparent;
		cursor: pointer;

		@include media($global-mq-mobile...) {
			width: 50px;
		}

		.c-slide__button-icon {
			width: 24px;
			height: 24px;
			fill: $slide-button-active;
		}

		&.disabled {
			cursor: default;

			.c-slide__button-icon {
				fill: $slide-button-disabled;
			}
		}
	}

	.owl-prev {
		order: 1;
	}

	.owl-next {
		order: 3;
	}

	.owl-dots {
		order: 2;
	}

	.owl-dot {

		&.active {
			margin-bottom: -3px;
			border-bottom: 3px solid $slide-button-active;

			.slider-paging__button {
				padding-bottom: 7px;
				color: $slide-button-active;
			}
		}

		.slider-paging__button {
			border: none;
			padding: 10px;
			width: 50px;
			font-size: 3rem;
			line-height: (28/ 30);
			color: $slide-button;
			background: none;
			cursor: pointer;
		}
	}
}
