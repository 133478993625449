.c-teaser-seal {

	&__title {
		@include heading(headline-4, $ci-secondary-headline-color, 30);
		font-weight: 600;

		@include media($global-mq-mobile...) {
			margin-bottom: 20px;
			font-size: rem(16);
		}
	}

	&__figure {
		margin: 0 auto;
		max-width: 350px;

		img {
			display: block;
			margin: 0 auto;
			max-width: 100%;
			max-height: 100%;
		}
	}
}
