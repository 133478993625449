// define grid row container
@mixin grid-row {
	@include clearfix();
	margin-right: -1 * $global-grid-gap-width / 2;
	margin-left: -1 * $global-grid-gap-width / 2;
}

// base col definitions
@mixin _grid-col-base() {
	float: left;
	padding-right: $global-grid-gap-width / 2;
	padding-left: $global-grid-gap-width / 2;
	min-height: 1px;
}

// grid col mixin
@mixin grid-col($col) {
	@if unitless($col) {
		@if $col < 0 {
			@error '$col have to be bigger than 0, was #{$col}.';
		} @else if $col > 12 {
			@error '$col have to be smaller than 13, was #{$col}.';
		} @else {
			width: percentage($col / 12);
		}
	} @else {
		@error '$col have to be unitless, was #{$col}.';
	}
}

// col offset mixin
@mixin grid-offset($col) {
	@if unitless($col) {
		@if $col < 0 {
			@error '$col have to be bigger than 0, was #{$col}.';
		} @else if $col > 12 {
			@error '$col have to be smaller than #{$global-grid-colmuns + 1}, was #{$col}.';
		} @else {
			margin-left: percentage($col / $global-grid-colmuns);
		}
	} @else {
		@error '$col have to be unitless, was #{$col}.';
	}
}

// generation classes

.u-row {
	@include grid-row;
}

[class*=u-col-] {
	@include _grid-col-base;
}

@for $i from 1 through $global-grid-colmuns {

	.u-col-#{$i} {
		@include grid-col($i);
	}

	.u-offset-#{$i} {
		@include grid-offset($i);
	}
}

// large
@include media($global-mq-desktop...) {
	@for $i from 1 through $global-grid-colmuns {

		.u-col-#{$i}\@lg {
			@include grid-col($i);
		}

		.u-offset-#{$i}\@lg {
			@include grid-offset($i);
		}
	}
}

// medium
@include media($global-mq-tablet...) {
	@for $i from 1 through $global-grid-colmuns {

		.u-col-#{$i}\@md {
			@include grid-col($i);
		}

		.u-offset-#{$i}\@md {
			@include grid-offset($i);
		}
	}
}

// small
@include media($global-mq-mobile...) {
	@for $i from 1 through $global-grid-colmuns {

		.u-col-#{$i}\@sm {
			@include grid-col($i);
		}

		.u-offset-#{$i}\@sm {
			@include grid-offset($i);
		}
	}
}
