.c-menu {
	display: flex;
	flex: 1 0 auto;
	align-items: center;
	justify-content: center;

	@include media($global-mq-tablet-and-mobile...) {
		position: absolute;
		top: 100%;
		right: 0;
		left: 0;
		flex-wrap: wrap;
		align-content: flex-start;
		height: 0;
		overflow: hidden;
	}

	.c-lang-switch {
		margin-right: 30px;

		@include media($global-mq-desktop-and-header...) {
			margin-right: 15px;
		}

		@include media($global-mq-tablet-and-mobile...) {
			flex: 0 0 100%;
			margin: 0;
			text-align: center;
			background-color: $lang-switch-bg-color;

			.c-lang-switch__link {
				color: $lang-switch-link-color;
			}
		}

		&__link {
			font-weight: 500;
			line-height: (30/18);

			@include media($global-mq-desktop...) {
				display: block;

				&.active {
					display: none;
				}
			}

			@include media($global-mq-desktop-and-header...) {
				font-size: 1.6rem;
				line-height: (30/16);
			}

			@include media($global-mq-tablet-and-mobile...) {
				display: inline-block;
				padding: 0 20px;
				font-weight: 400;
				line-height: (50/18);

				&.active {
					font-weight: 600;
					text-decoration: underline;
				}

				&:first-child {
					position: relative;

					&::after {
						content: '';
						position: absolute;
						top: 16px;
						right: -2px;
						display: block;
						width: 1px;
						height: 18px;
						background-color: $lang-switch-separator-color;
					}
				}
			}
		}
	}
}

.c-menu__toggle {
	position: relative;
	flex: 0 0 auto;
	order: -1;
	width: 26px;
	height: 26px;
}

.c-menu__toggle--bar {
	position: absolute;
	left: 0;
	display: block;
	width: 100%;
	height: 2px;
	background-color: $header-icon-color;
	opacity: 1;
	transform: rotate(0deg);
	transition: .25s ease-in-out;

	&:nth-child(2) {
		top: 2px;
	}

	&:nth-child(3) {
		top: 10px;
	}

	&:nth-child(4) {
		top: 18px;
	}
}

.c-menu__button:checked {

	+ .c-menu__toggle {

		.c-menu__toggle--bar {

			&:nth-child(2) {
				top: 10px;
				transform: rotate(135deg);
			}

			&:nth-child(3) {
				opacity: 0;
			}

			&:nth-child(4) {
				top: 10px;
				transform: rotate(-135deg);
			}
		}
	}

	~ .c-menu {
		height: calc(100vh - 80px);
		overflow-y: auto;

		@include media($global-mq-desktop...) {
			height: auto;
			overflow: visible;
		}
	}
}
