%stage-separator {
	content: '';
	display: block;
	width: 100px;
	height: 1px;
}

.c-stage__content {

	.contenttype-overview &,
	.contenttype-frontpage & {
		position: relative;
		margin-left: -20px;
		padding: 40px 20px;
		width: 66.666%;
		max-width: 75rem;

		@include media($global-mq-tablet-and-mobile...) {
			margin-left: 0;
			padding: 20px 0;
			width: 100%;
			max-width: 100%;
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: -100vw;
			z-index: -1;
			background-color: $stage-content-bg;
			opacity: .1;

			@include media($global-mq-tablet-and-mobile...) {
				right: -15px;
				left: -15px;
			}
		}
	}

	.contenttype-detail & {
		flex: 1 1 100%;
		margin-top: auto;
		padding-top: 50px;

		@include media($global-mq-tablet-and-mobile...) {
			padding-top: 0;
		}
	}
}
