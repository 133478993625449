.c-stage {
	position: relative;

	.contenttype-frontpage & {

		@include media($global-mq-mobile...) {
			padding-top: 110px;
		}
	}

	.contenttype-detail & {
		display: flex;
		justify-content: flex-end;

		@include media($global-mq-tablet-and-mobile...) {
			flex-direction: column;
		}
	}
}
