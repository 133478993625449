.c-form {
	position: relative;
	max-width: 650px;

	@include media($global-mq-mobile...) {
		overflow: hidden;
	}

	&__item {
		position: relative;
		display: flex;
		flex-flow: column-reverse;
		margin-bottom: 12px;

		&:first-child {
			margin-top: -1.8rem;

			@include media($global-mq-mobile...) {
				margin-top: -1.6rem;
			}
		}

		&:last-child {
			margin-bottom: 0;
		}

		&.has-error {

			&:after {
				content: '!';
				position: absolute;
				top: 52px;
				right: -44px;
				display: block;
				border-radius: 50%;
				width: 24px;
				height: 24px;
				font-size: 1.6rem;
				font-weight: 600;
				line-height: (24/16);
				text-align: center;
				color: #fff;
				background-color: $ci-input-error;

				@include media($global-mq-tablet-and-mobile...) {
					top: 10px;
					right: auto;
					left: 0;
					width: 20px;
					height: 20px;
					line-height: (20/16);
				}
			}
		}
	}

	&__submit,
	&__markup {
		margin-top: 40px;
	}

	&__button {
		align-self: center;
		max-width: 26rem;
		cursor: pointer;
	}

	&__description {
		display: block;
		margin: 40px auto 0;
		max-width: 65rem;

		@include media($global-mq-tablet-and-mobile...) {
			margin-top: 20px;
		}
	}

	&__thank-you {
		position: absolute;
		top: 0;
		right: -200px;
		left: -200px;
		width: auto;
		height: 100%;
		text-align: center;
		background-color: $form-thank-you-bg-color;
		visibility: hidden;
		opacity: 0;
		transition: visibility 0s .2s, opacity .2s linear;

		@include media($global-mq-tablet...) {
			right: -100px;
			left: -100px;
		}

		@include media($global-mq-mobile...) {
			right: 0;
			left: 0;
		}

		&.is-active {
			visibility: visible;
			opacity: 1;
			transition: opacity .2s linear;
		}

		&--title {
			@include heading(headline-2, $ci-secondary-headline-color, 20);
			font-weight: 600;
		}
	}

	/**
	* https://css-tricks.com/float-labels-css
	* Add a transition to the label and input.
	* I'm not even sure that touch-action: manipulation works on
	* inputs, but hey, it's new and cool and could remove the pesky delay.
	*/
	&__textarea,
	&__label,
	&__input {
		transition: all .3s;
		touch-action: manipulation;
	}

	&__label {
		position: relative;
		top: 23px;
		left: 15px;
		font-size: 1.2rem;

		@include media($global-mq-mobile...) {
			font-size: 1.1rem;
		}
	}

	&__textarea,
	&__input {
		padding-top: 24px;
		padding-bottom: 6px;

		/**
		* https://css-tricks.com/float-labels-css
		* By default, the placeholder should be transparent.
		* Also, it should inherit the transition.
		*/

		&::-webkit-input-placeholder {
			color: $ci-color-white;
		}

		&::-moz-placeholder {
			color: $ci-color-white;
		}

		&:-ms-input-placeholder {
			color: $ci-color-white;
		}
	}
}

@supports (display: block) {
	.c-form {

		&__label {
			top: 0;
			left: 0;
		}

		&__textarea,
		&__input {

			/**
			* https://css-tricks.com/float-labels-css
			* Translate down and scale the label up to cover the placeholder,
			* when following an input (with placeholder-shown support).
			*/

			+ .c-form__label {
				max-width: 370px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				cursor: text;
				transform-origin: left bottom;
				transform: translate(15px, 40px) scale(1.5);
			}

			/**
			* https://css-tricks.com/float-labels-css
			* When the element is focused, remove the label transform.
			* Also, do this when the placeholder is _not_ shown, i.e. when there's something in the input at all.
			*/
			&:not(:placeholder-shown),
			&:focus {

				+ .c-form__label {
					max-width: 560px;
					opacity: .5;
					transform: translate(15px, 24px) scale(1);
					cursor: pointer;
				}
			}
		}

		&__item {
			// for edge
			&.focus {

				.c-form__label {
					max-width: 560px;
					opacity: .5;
					transform: translate(15px, 24px) scale(1);
					cursor: pointer;
				}
			}
		}
	}
}
