.c-teaser-employee {
	position: relative;
	margin: 0 auto;
	max-width: 450px;

	@include hocus() {

		.c-teaser-employee__data-wrapper {
			top: 0;
		}

		.c-teaser-employee__link-items {
			max-height: 172px;
		}
	}

	&__figure {

		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}

	&__data-wrapper {
		position: absolute;
		top: calc(100% - 142px);
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 40px 20px;
		background-color: $employee-data-bg;
		transition: top .1s linear;
	}

	&__data {
		font-size: 2rem;
		line-height: (26/20);
		color: $employee-text;

		&-item {
			display: block;

			&:first-of-type {
				margin-bottom: 10px;
				font-weight: 600;
			}
		}
	}

	&__link-items {
		max-height: 0;
		overflow: hidden;
		transition: max-height .1s linear;
	}

	&__link-item {
		position: relative;
		display: inline-flex;
		align-items: flex-start;
		justify-content: center;
		padding: 10px;
		width: 100%;
		color: $employee-text;

		&:first-child {
			margin-top: 40px;
		}

		@include hocus() {
			text-decoration: none;
		}

		&--svg {
			flex-shrink: 0;
			order: 1;
			margin-right: 10px;
			width: 18px;
			height: 18px;
			fill: $employee-icon;
		}

		&--text {
			order: 2;
		}
	}
}
