fieldset {
	margin: 0 0 30px;
	border: none;
	padding: 0;

	&:last-child {
		margin-bottom: 0;
	}

	legend {
		font-weight: 500;
	}
}
