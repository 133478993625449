.c-stage__link {
	display: block;
	margin-top: 30px;
	border: 2px solid $stage-border;
	border-radius: 5px;
	padding: 15px 0;
	width: 265px;
	font-size: rem(18);
	font-weight: 600;
	text-transform: uppercase;
	text-align: center;
	color: $stage-link;
	transition: background-color .1s linear;

	@include hocus() {
		text-decoration: none;
		background-color: rgba(255, 255, 255, .2);
	}

	@include media($global-mq-tablet-and-mobile...) {
		margin-right: auto;
		margin-left: auto;
	}

	@include media($global-mq-mobile...) {
		font-size: rem(16);
	}
}
