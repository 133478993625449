.c-quotes {
	padding: 80px 0;
	background-position: center center;
	background-size: cover;

	&__text {
		position: relative;
		padding: 35px 0;
		font-size: rem(25);
		line-height: (40/25);
		color: $quotes-text;
		font-style: italic;

		@include media($global-mq-tablet...) {
			font-size: rem(20);
			line-height: (28/20);
		}

		@include media($global-mq-mobile...) {
			font-size: rem(16);
			line-height: (22/16);
		}
	}

	&__author {
		display: block;
		margin-top: 30px;
		font-size: rem(20);
		font-weight: 600;
		line-height: (38/20);
		font-style: normal;
		color: $quotes-text;

		@include media($global-mq-tablet...) {
			font-size: rem(16);
			line-height: (28/16);
		}

		@include media($global-mq-mobile...) {
			font-size: rem(14);
			line-height: (22/14);
		}
	}
}
