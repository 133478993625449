.c-logo {
	flex: 0 1 auto;
	margin-top: 8px;

	@include media($global-mq-desktop-and-header...) {
		margin-top: 6px;
	}

	@include media($global-mq-tablet-and-mobile...) {
		flex-grow: 0;
		margin-left: auto;
	}

	&__svg {
		margin-top: 0;
		max-width: 220px;
		max-height: 57px;

		@include media($global-mq-desktop-and-header...) {
			max-width: 180px;
		}

		@include media($global-mq-tablet-and-mobile...) {
			max-width: 150px;
			max-height: 40px;
		}
	}
}
