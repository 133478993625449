.c-newsletterform {
	position: relative;

	&__input {
		padding-right: 70px;
	}

	&__btn {
		position: absolute;
		top: 0;
		right: 30px;
		display: block;
		border: none;
		border-radius: 0 5px 5px 0;
		width: 56px;
		height: 56px;
		background-color: $ci-btn-bg;
		cursor: pointer;

		@include media($global-mq-tablet-and-mobile...) {
			right: 15px;
		}
	}

	&__icon {
		position: relative;
		top: 2px;
		width: 32px;
		height: 32px;
		fill: $ci-btn-icon;
	}
}
