.c-teaser-career {
	overflow: hidden;

	&__figure {
		display: flex;
		align-items: center;
		align-content: center;
		margin-bottom: 20px;
		border: 1px solid $career-border;
		padding: 20px;
		height: 240px;

		@include media($global-mq-mobile...) {
			height: 180px;
		}

		> img {
			display: block;
			margin: auto;
			width: 100%;
			height: auto;
		}
	}

	&__link {
		display: block;

		@include hocus() {
			text-decoration: none;

			.e-link {

				&::after {
					opacity: 1;
				}
			}

			.e-link--external {

				&::after {
					opacity: 0;
				}
			}
		}
	}

	&__info {
		margin-bottom: 10px;
		overflow: hidden;
		color: $career-teaser-info;

		> span {
			float: left;
			display: block;

			&::after {
				content: '|';
				padding: 0 5px;
			}

			&:last-child {

				&::after {
					content: '';
					padding: 0;
				}
			}
		}
	}

	&__title {
		@include heading(headline-4, $ci-secondary-headline-color, 20);
		font-weight: 600;
		text-transform: none;

		@include media($global-mq-mobile...) {
			font-size: rem(18);
		}
	}

	&__description {
		margin-bottom: 30px;
		color: $career-teaser-content;

		@include media($global-mq-tablet-and-mobile...) {
			margin-bottom: 20px;
		}
	}
}
