.c-gendertip {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	margin-right: 30px;

	@include media($global-mq-desktop-and-header...) {
		margin-right: 15px;
	}

	@include media($global-mq-tablet-and-mobile...) {
		flex: 0 0 100%;
		margin: 0;
		border-top: 1px solid $gendertip-border-color;
		background-color: $gendertip-bg-color;
	}

	&__toggle {
		padding-top: 3px;
		cursor: pointer;

		@include media($global-mq-tablet-and-mobile...) {
			display: flex;
			align-items: center;
			padding: 0 20px;
			width: 100%;
			font-size: 1.6rem;
			line-height: (50/16);
		}
	}

	&__icon {
		width: 20px;
		height: 18px;

		@include media($global-mq-tablet-and-mobile...) {
			margin-right: 12px;
			width: 22px;
			height: 22px;
			fill: $gendertip-icon-fill;
		}
	}

	&__label-text {

		@include media($global-mq-tablet-and-mobile...) {
			flex-grow: 1;
			color: $gendertip-label-color;
		}
	}

	&__arrow {

		@include media($global-mq-tablet-and-mobile...) {
			width: 18px;
			height: 18px;
			transform: rotate(90deg);
			fill: $gendertip-icon-fill;
		}
	}

	&__content {
		display: none;
		padding: 20px;
		font-size: 1.2rem;
		line-height: 1.5;
	}
}

.c-gendertip__button:checked {

	+ .c-gendertip__toggle {

		.c-gendertip__arrow {
			transform: rotate(-90deg);
		}
	}

	~ .c-gendertip__content {
		display: block;

		@include media($global-mq-desktop...) {
			position: absolute;
			top: 66px;
			left: 50%;
			width: 232px;
			transform: translate(-50%, 0);
			background-color: $gendertip-layer;
			transition: top .3s ease;

			&::before {
				content: '';
				position: absolute;
				top: -10px;
				right: 0;
				left: 0;
				margin: 0 auto;
				border-style: solid;
				border-width: 0 10px 10px;
				border-color: transparent transparent $gendertip-layer-arrow;
				width: 0;
				height: 0;
				transform: rotate(360deg);
			}

			.c-header--scrolling & {
				top: 51px;
			}
		}

		@include media($global-mq-tablet-and-mobile...) {
			flex-grow: 1;
			font-size: 1.6rem;
			color: $gendertip-text-color;
		}
	}
}
