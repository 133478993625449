.c-download-link {

	&__title {
		margin-bottom: 10px;
		font-size: 2rem;
		font-weight: 400;
		line-height: (24/20);
		text-transform: none;
		color: $download-link-title;
	}
}
