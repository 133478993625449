.c-teaser-bubble {
	position: relative;

	&__link {
		display: block;

		@include hocus() {
			text-decoration: none;

			.c-teaser-bubble__figure {
				transform: scale(1.05);
			}

			.e-link {

				&::after {
					opacity: 1;
				}
			}
		}
	}

	&__figure {
		position: relative;
		box-shadow: 0 1px 5px 1px $bubble-shadow;
		margin: 0 auto 20px;
		border-radius: 50%;
		width: 200px;
		height: 200px;
		background-color: $bubble-bg;
		transform: scale(1);
		transition: transform .1s linear;

		@include media($global-mq-mobile...) {
			width: 150px;
			height: 150px;
		}
	}

	&__content {
		color: $ci-default-base-color;
	}

	&__icon {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 120px;
		height: 120px;
		transform: translate(-60px, -55px);
		fill: $bubble-icon;

		@include media($global-mq-mobile...) {
			width: 80px;
			height: 80px;
			transform: translate(-40px, -35px);
		}
	}
}
