.c-stage__title {
	@include heading(headline-1, $stage-headline, 30);
	font-weight: 500;

	@include media($global-mq-tablet...) {
		margin-bottom: 20px;
		font-size: rem(30);
		text-align: center;
	}

	@include media($global-mq-mobile...) {
		margin-bottom: 20px;
		font-size: rem(24);
		text-align: center;
	}

	.contenttype-detail & {
		color: $stage-detail-headline;
	}
}
