.o-wrap {
	@include clearfix();
	margin-right: auto;
	margin-left: auto;
	padding-right: ($global-page-site-padding * 1px);
	padding-left: ($global-page-site-padding * 1px);
	max-width: rem($global-page-width);

	@include media($global-mq-tablet-and-mobile...) {
		padding-right: ($global-page-site-padding-mobile * 1px);
		padding-left: ($global-page-site-padding-mobile * 1px);
	}

	.o-wrap & {
		padding: 0;
	}

	&--medium {
		max-width: rem($global-page-width-medium);
	}

	&--small {
		max-width: rem($global-page-width-small);
	}

	&--wide {
		padding-top: 60px;
		padding-bottom: 60px;
		max-width: 100%;
		background-color: $ci-wide-background;

		@include media($global-mq-tablet-and-mobile...) {
			padding-top: 40px;
			padding-bottom: 40px;
		}

		&:last-child {
			margin-bottom: -100px;
			padding-bottom: 100px;

			@include media($global-mq-tablet-and-mobile...) {
				margin-bottom: -60px;
				padding-bottom: 60px;
			}
		}

		+ .o-wrap--wide {
			margin-top: -60px;

			@include media($global-mq-tablet-and-mobile...) {
				margin-top: -40px;
			}
		}

		&.o-page-module--big {

			+ .o-wrap--wide {
				margin-top: -100px;

				@include media($global-mq-tablet-and-mobile...) {
					margin-top: -60px;
				}
			}
		}

		&.c-tabslider {
			padding-bottom: 30px;
		}
	}
}
