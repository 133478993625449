.c-teaser-portfolio {

	&__wrapper {

		.o-wrap--wide & {
			margin: 0 auto;
			padding-right: ($global-page-site-padding * 1px);
			padding-left: ($global-page-site-padding * 1px);
			max-width: rem($global-page-width);

			@include media($global-mq-mobile...) {
				padding: 0;
			}
		}
	}

	&__items {
		display: flex;
		flex-direction: row;
		justify-content: space-evenly;
		margin: 0 -15px 60px;

		.slick-initialized & {
			display: block;
		}
	}

	&__item {
		padding: 0 20px;

		img {
			display: block;
			width: 100%;
			max-width: 150px;
			height: auto;
			filter: grayscale(100%);
			transition: filter .1s linear;

			.slick-initialized & {
				margin: 0 auto;
			}
		}

		@include hocus() {

			img {
				filter: grayscale(0%);
			}
		}
	}

	.c-slide__button {
		position: absolute;
		top: 50%;
		z-index: 5;
		border: none;
		padding: 10px;
		width: 50px;
		height: 50px;
		font-size: 3rem;
		line-height: (28/ 30);
		color: $slide-button;
		background: transparent;
		cursor: pointer;
		transform: translate(0, -50%);

		&-icon {
			width: 30px;
			height: 30px;
			fill: $slide-button-active;
		}

		&--left {
			left: 0;

			@include media($global-mq-tablet-and-mobile...) {
				left: -15px;
				padding-left: 15px;
			}
		}

		&--right {
			right: 0;

			@include media($global-mq-tablet-and-mobile...) {
				right: -15px;
				padding-right: 15px;
			}
		}
	}

	.slick-list {

		@include media($global-mq-tablet-and-mobile...) {
			margin: 0 30px;
		}
	}
}
