.pi-newsletter-widget {
	margin-bottom: 60px;

	&:last-child {
		margin-bottom: 0;
	}

	@include media($global-mq-tablet-and-mobile...) {
		margin-bottom: 40px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.pi-heading {
		display: none;
	}

	.pi-form-control__has-error,
	.pi-form-actions,
	.pi-message,
	.pi-form {
		margin: 0 auto;
		padding-right: ($global-page-site-padding * 1px);
		padding-left: ($global-page-site-padding * 1px);
		max-width: 65rem;

		@include media($global-mq-tablet-and-mobile...) {
			padding-right: 0;
			padding-left: 0;
		}

		.pi-form-control__has-error {
			margin: 0;
			padding-right: 0;
			padding-left: 0;
		}

		.pi-form-control {

			&.pi-form-control__has-error {

				label {
					color: $ci-input-error;
				}

				input {
					border-color: $ci-input-error;
				}
			}

			&[data-pi-id='permission'],
			&[data-pi-id='salut'] {

				.pi-form-label {
					display: none;
				}

				.pi-radio-control,
				.pi-checkbox-control-vertical {
					display: inline-block;

					label {
						float: left;
						bottom: 0;
						padding-left: 5px;
						font-size: rem(16);
						line-height: 20px;
					}

					input {
						float: left;
						position: relative;
						top: 2px;
						padding: 0;
						width: 14px;
						height: 14px;
					}
				}
			}

			&[data-pi-id='permission'] {
				margin-top: 20px;
				margin-bottom: 20px;

				.pi-checkbox-control-vertical {
					position: relative;

					input {
						position: absolute;
						top: 0;
						left: 0;
					}

					label {
						padding-left: 25px;
					}
				}
			}
		}

		textarea,
		input {
			border: 1px solid $ci-input-border;
			border-radius: 5px;
			padding: 24px 15px 6px;
			width: 100%;
			font-size: rem(18);
			line-height: (24/18);

			@include hocus() {
				outline: none;
			}

			@include media($global-mq-tablet-and-mobile...) {
				font-size: rem(16);
			}
		}

		textarea {
			padding-bottom: 15px;
			resize: none;
		}

		label {
			position: relative;
			bottom: -26px;
			padding: 0 15px;
			font-size: rem(12);
			opacity: .5;
		}

		.select {
			margin-top: 30px;

			label {
				bottom: 0;
				padding-left: 0;
				font-size: rem(16);
				opacity: 1;
			}
		}

		.checkbox {
			margin-top: 30px;

			.form-label {
				display: none;
			}

			fieldset {
				margin-bottom: 0;
			}

			ul {
				margin: 0;
				padding: 0;

				li {
					list-style: none;
				}

				input {
					position: relative;
					top: 0;
					display: inline-block;
					width: auto;
				}

				label {
					bottom: 0;
					display: inline-block;
					padding-right: 0;
					padding-left: 5px;
					font-size: rem(16);
					opacity: 1;
				}
			}
		}
	}

	.pi-form {
		margin-bottom: 40px;

		@include media($global-mq-tablet-and-mobile...) {
			margin-bottom: 20px;
		}
	}

	.pi-form-control__has-error {
		margin-bottom: 40px;

		@include media($global-mq-tablet-and-mobile...) {
			margin-bottom: 20px;
		}

		p {
			margin-bottom: 0;
		}

		ul {
			li {
				color: $ci-input-error;
			}
		}
	}

	.pi-form-actions {

		.pi-form-button {
			text-align: center;
		}

		button[type='submit'] {
			@extend %o-btn;
			display: block;
			margin: 0 auto 10px;

			@include hocus() {
				text-decoration: none;
				box-shadow: 0 2px 10px 2px $ci-btn-shadow;
			}

			@include media($global-mq-tablet-and-mobile...) {
				font-size: rem(16);
				line-height: (22/16);
			}
		}
	}
}
