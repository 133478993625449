/**
 * Default styles for simple hyperlinks.
 */

a {
	text-decoration: none;
	color: $ci-link-color;

	@include hocus() {
		text-decoration: underline;
	}
}

.e-link {
	position: relative;
	display: inline-flex;
	align-items: flex-start;

	&::after {
		content: '';
		position: absolute;
		right: 0;
		bottom: -3px;
		left: 0;
		display: block;
		width: 100%;
		height: 1px;
		background-color: $ci-link-decoration;
		opacity: 0;
		transition: opacity .1s linear;
	}

	@include hocus() {
		text-decoration: none;

		&::after {
			opacity: 1;
		}
	}

	&__icon {
		flex-shrink: 0;
		order: 1;
		margin-right: 9px;
		width: 8px;
		height: 20px;
		fill: $ci-link-icon-color;

		&--download {
			width: 10px;
		}
	}

	&__text {
		order: 2;
		font-weight: 500;
		font-style: italic;
	}

	&--external {

		@include hocus() {
			&::after {
				opacity: 0;
			}
		}

		.e-link__icon {
			width: 20px;
		}
	}
}
