.c-nav {
	flex: 1 0 auto;
	margin-right: 30px;

	@include media($global-mq-desktop-and-header...) {
		margin-right: 15px;
	}

	@include media($global-mq-tablet-and-mobile...) {
		flex: 0 0 100%;
		order: -1;
		margin-right: 0;
		background-color: $nav-mobile-bg-color;
	}
}

.c-nav__title {
	margin: 0;
	border-top: 2px solid $nav-border-top-color;
	border-bottom: 1px solid $nav-border-bottom;
	padding: 20px 0;
	font-size: 2rem;
	text-align: center;
}

.c-nav__list {
	display: flex;
	align-items: center;
	justify-content: space-between;

	@include media($global-mq-tablet-and-mobile...) {
		flex-direction: column;
		align-items: stretch;
	}
}

.c-nav__item {
	flex: 1 0 auto;
	margin: 0;
	text-align: center;

	&::before {
		content: none;
	}

	@include media($global-mq-tablet-and-mobile...) {
		//padding: 14px 0;
	}

	+ .c-nav__item {

		@include media($global-mq-tablet-and-mobile...) {
			border-top: 1px solid $nav-item-border-color;
		}
	}
}

.c-nav__link {
	display: block;
	font-weight: 500;
	line-height: (30/18);

	&.active {
		font-weight: 600;
		text-decoration: underline;
	}

	@include media($global-mq-desktop-and-header...) {
		font-size: 1.4rem;
		line-height: (30/14);
	}

	@include media($global-mq-tablet-and-mobile...) {
		display: block;
		padding: 0 20px;
		font-size: 1.8rem;
		line-height: (50/18);
	}
}
