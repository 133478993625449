.c-portfolio {

	.c-teaserlist__wrapper {

		@include media($global-mq-mobile...) {
			flex-direction: row;
		}
	}

	.c-teaserlist__item {
		width: 25%;
		transition: margin .25s ease-in;

		@include media($global-mq-desktop...) {
			&:nth-child(7n) {
				margin-right: 12.5%;
			}

			&:nth-child(7n-2) {
				margin-left: 12.5%;
			}
		}

		@include media($global-mq-tablet-and-mobile...) {

			:root & {
				margin-bottom: 20px;
			}
		}

		@include media($global-mq-tablet...) {
			width: percentage(1 / 3);

			&:nth-child(5n) {
				margin-right: percentage(1 / 6);
			}

			&:nth-child(5n-1) {
				margin-left: percentage(1 / 6);
			}
		}

		@include media($global-mq-mobile...) {
			width: 50%;

			&:last-child {
				margin-right: auto;
				margin-left: auto;
			}
		}
	}
}
