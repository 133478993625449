.c-heading {

	&__note {
		margin: 0 auto 25px;
		max-width: 890px;
		font-size: rem(18);
		line-height: (24/18);

		.e-color--invert & {
			color: $ci-inverted-text-color;

			a {
				text-decoration: underline;
				color: $ci-inverted-text-color;
			}
		}

		@include media($global-mq-mobile...) {
			margin-bottom: 20px;
			font-size: rem(16);
		}
	}

	&.o-wrap--wide {
		margin-bottom: 0;
	}
}
