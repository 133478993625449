.c-stage__figure {

	@include media($global-mq-tablet-and-mobile...) {
		overflow: hidden;
	}

	> picture {

		@include media($global-mq-mobile...) {
			display: block;
			margin: 0 -70vw 0 -30vw;
		}
	}

	img {
		display: block;
		width: 100%;
		height: auto;
	}

	.contenttype-frontpage & {
		height: 100vh;
		background-repeat: no-repeat;
		background-size: cover;

		@include media($global-mq-mobile...) {
			height: calc(100vh - 110px);
		}
	}

	.contenttype-detail & {
		order: 1;
		width: 50%;

		@include media($global-mq-tablet-and-mobile...) {
			order: 0;
			margin-bottom: 60px;
			width: 100%;
		}
	}
}
