.c-search {
	display: flex;
	flex: 0 1 auto;
	align-items: center;
	justify-content: flex-end;
	margin-right: 30px;

	@include media($global-mq-desktop-and-header...) {
		margin-right: 15px;
	}

	@include media($global-mq-tablet-and-mobile...) {
		flex-grow: 0;
		margin-left: 15px;
	}

	&__close,
	&__icon,
	&__toggle {
		width: 20px;
		height: 20px;
		cursor: pointer;

		@include media($global-mq-tablet-and-mobile...) {
			position: relative;
			top: -1px;
		}
	}

	&__close {
		display: none;
	}
}

.c-search__button:checked {

	+ .c-search__toggle {

		.c-search__icon {
			display: none;
		}

		.c-search__close {
			display: block;
		}
	}

	~ .c-searchform {
		display: block;
	}
}
