table {
	border-collapse: collapse;
	margin-bottom: 2.4rem;
	width: 100%;

	&:last-child {
		margin-bottom: 0;
	}

	td {
		border: 1px solid $ci-table-border;
		padding: 5px;
	}
}
