// Mixin to drop micro clearfix into a selector.
//
// .simple-usage {
//   @include clearfix();
// }
//
// .detailed-usage {
//   @include clearfix(important);
// }

@mixin clearfix($important: false) {

	// Determine whether or not to make declarations important.
	@if ($important == false) {
		$important: null;
	} @else {
		$important: unquote('!important');
	}

	&::after {
		content: '' $important;
		clear: both $important;
		display: table $important;
	}
}
