.o-column {
	@include clearfix();
	margin-bottom: 1rem;

	&:last-child {
		margin-bottom: 0;
	}

	&--left,
	&--right {
		float: left;
		width: 50%;

		@include media($global-mq-tablet-and-mobile...) {
			float: none;
			padding: 0;
			width: 100%;
		}
	}

	&--left {
		padding-right: 15px;

		@include media($global-mq-tablet-and-mobile...) {
			margin-bottom: 1rem;
		}
	}

	&--right {
		padding-left: 15px;
	}
}
