.o-textarea {
	border: 1px solid $ci-input-border;
	border-radius: 5px;
	padding: 15px;
	width: 100%;
	font-size: 1.8rem;
	line-height: (24/18);
	resize: none;

	@include hocus() {
		outline: none;
	}

	&::placeholder {
		font-weight: 400;
		font-style: italic;
		color: $ci-input-placeholder;
	}
}
