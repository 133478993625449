.c-stage__text {
	font-size: 2rem;
	line-height: (28/20);
	color: $stage-content;

	@include media($global-mq-tablet...) {
		font-size: rem(18);
		line-height: (24/18);
		text-align: center;
	}

	@include media($global-mq-mobile...) {
		font-size: rem(16);
		line-height: (22/16);
	}

	.contenttype-detail & {
		color: $stage-detail-content;

		&::after {
			@extend %stage-separator;
			margin-top: 20px;
			background-color: $separator-bg;

			@include media($global-mq-tablet-and-mobile...) {
				margin-right: auto;
				margin-left: auto;
			}
		}
	}
}
