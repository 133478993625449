.c-teaser-news {

	&__link {
		display: block;

		@include hocus() {
			text-decoration: none;

			.e-link {

				&::after {
					opacity: 1;
				}
			}
		}
	}

	&__figure {
		margin-bottom: 20px;

		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}

	&__title {
		@include heading(headline-4, $ci-secondary-headline-color, 10);
		font-weight: 600;
		text-transform: none;

		@include media($global-mq-mobile...) {
			font-size: rem(16);
		}
	}

	&__date {
		display: block;
		margin-bottom: 10px;
		color: $news-teaser-date;
	}

	&__content {
		color: $news-teaser-content;
	}
}
