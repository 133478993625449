@mixin border-separator {
	content: '';
	position: absolute;
	right: 15px;
	left: 15px;
	border-top: 1px solid $teaserlist-border;
}

.c-teaserlist {

	&__wrapper {
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin: 0 -15px;

		@include media($global-mq-tablet-and-mobile...) {
			flex-direction: column;
		}

		&.slick-initialized {
			display: block;
			overflow: hidden;
		}

		.contenttype-overview & {
			flex-wrap: wrap;
			justify-content: flex-start;

			@include media($global-mq-tablet...) {
				flex-direction: row;
			}
		}

		.o-wrap--wide & {
			justify-content: center;
			margin: 0 auto;
			padding-right: ($global-page-site-padding * 1px);
			padding-left: ($global-page-site-padding * 1px);
			max-width: rem($global-page-width);

			@include media($global-mq-tablet-and-mobile...) {
				margin: 0 -15px;
				padding: 0;
			}
		}

		.c-employee-slider & {
			padding-right: 15px;
			padding-left: 15px;

			@include media($global-mq-desktop-and-header...) {
				margin-right: -15px;
				margin-left: -15px;
				padding-right: 0;
				padding-left: 0;
			}
		}
	}

	&__item {
		padding: 0 15px;
		width: 100%;

		@include media($global-mq-tablet-and-mobile...) {
			margin-bottom: 20px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.slick-initialized & {

			.c-teaser-news {
				max-width: 100%;
			}
		}

		.contenttype-overview & {
			margin-bottom: 40px;
			width: 33.333%;

			@include media($global-mq-tablet...) {
				margin-bottom: 40px;
				width: 50%;

				&:last-child {
					margin-bottom: 40px;
				}
			}

			@include media($global-mq-mobile...) {
				margin-bottom: 20px;
				width: 100%;

				&:last-child {
					margin-bottom: 20px;
				}
			}
		}

		.c-teaser-icon-list & {
			margin-bottom: 0;

			@include media($global-mq-tablet...) {
				width: 100%;
			}
		}

		.c-employee-slider & {

			@include media($global-mq-tablet...) {
				width: 100%;
			}
		}
	}

	&--border {

		.c-teaserlist__item {
			padding-top: 60px;

			&:nth-child(1),
			&:nth-child(2),
			&:nth-child(3) {
				padding-top: 0;
			}

			&:nth-child(2) {

				@include media($global-mq-mobile...) {
					padding-top: 40px;
				}
			}

			&:nth-child(3) {

				@include media($global-mq-tablet-and-mobile...) {
					padding-top: 40px;
				}
			}

			@include media($global-mq-tablet-and-mobile...) {
				padding-top: 40px;
			}

			@include media($global-mq-desktop...) {

				&:nth-child(3n+1) {

					&::before {
						@include border-separator;
						margin-top: -60px;
					}
				}

				&:nth-child(1) {

					&::before {
						border: none;
					}
				}
			}

			@include media($global-mq-tablet...) {

				&:nth-child(odd) {

					&::before {
						@include border-separator;
						margin-top: -40px;
					}
				}

				&:nth-child(1) {

					&::before {
						border: none;
					}
				}
			}

			@include media($global-mq-mobile...) {

				&::before {
					@include border-separator;
					margin-top: -40px;
				}

				&:nth-child(1) {

					&::before {
						border: none;
					}
				}
			}
		}
	}

	&--seal,
	&--contact {

		.c-teaserlist__item {
			width: 50%;

			&:last-child {
				margin-bottom: 0;
			}

			@include media($global-mq-tablet-and-mobile...) {
				width: 100%;
			}
		}
	}

	&--employee {
		.c-teaserlist__item {
			padding-right: 30px;
			padding-left: 30px;

			@include media($global-mq-tablet-and-mobile...) {
				padding-right: 15px;
				padding-left: 15px;
			}
		}
	}
}
