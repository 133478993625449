.e-validation {

	&__text {
		margin-top: 12px;
		margin-bottom: -12px;
		font-size: 1.2rem;
		font-weight: 600;
		color: $ci-input-error;

		@include media($global-mq-tablet-and-mobile...) {
			padding-left: 25px;
		}
	}
}
