// --------------------------------------------------------------------
// keep track of all your z-index values
// without getting lost in properties scattered all around the project
// see tools/functions/_layering.scss for a more detailed example
// --------------------------------------------------------------------
$z-layers: (
	'default': 0,
	'company-description': 5,
	'header': 10
);
